
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import KeywordField from "@/components/fields/KeywordField.vue"
import { UpdateScopeServiceTagsMutation, UpdateScopeServiceTagsMutationMutation } from "@/gql"
import { ScopeTag } from "@/gql"

@Component({
  components: { KeywordField },
})
export default class ServiceTagForm extends Vue {
  @Prop({ required: true }) readonly scopeServiceId!: BigInt
  @Prop({ required: true }) readonly tags!: ScopeTag[]

  form: Record<string, any> = {
    tags: [],
  }

  cachedTags: string[] = []

  loading = false

  async onSaveServiceTags() {
    this.loading = true

    const result = await this.mutate<UpdateScopeServiceTagsMutationMutation>({
      mutation: UpdateScopeServiceTagsMutation,
      variables: {
        serviceId: this.scopeServiceId,
        tags: this.form.tags,
      },
      done: () => (this.loading = false),
    })

    if (result.data?.updateScopeServiceTags?.scopeService) {
      this.addSuccess("Service tags updated!")
      this.close()
    }
  }

  @Watch("tags", { immediate: true, deep: true })
  populateTags() {
    this.cachedTags = this.tags.map((tag) => tag.name)

    this.form = {
      tags: [...this.cachedTags],
    }
  }

  @Emit("save")
  save() {
    return this.form
  }

  @Emit("close")
  close() {
    this.form = { tags: [] }
    return true
  }
}
