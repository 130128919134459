
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import TagChip from "@/components/widgets/common/TagChip.vue"
import ServiceTagForm from "../forms/ServiceTagForm.vue"
import { ScopeTag } from "@/gql"

@Component({
  components: {
    TagChip,
    ServiceTagForm,
  },
})
export default class ScopeLibraryTagBar extends Vue {
  @Prop({ required: true }) scopeServiceId!: BigInt
  @Prop({ required: true }) value!: ScopeTag[]
  @Prop({ default: true }) showEditButton!: boolean

  get policy() {
    return {
      canManageScopeLibrary: this.can("manage_scope_library"),
    }
  }

  showAddServiceTag = false

  loading = false
  tags: ScopeTag[] = []

  @Watch("value", { immediate: true, deep: true })
  populateTags() {
    this.tags = this.value
  }

  close() {
    this.showAddServiceTag = false
  }

  open() {
    this.showAddServiceTag = true
  }
}
